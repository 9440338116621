:global(:root) {
  @import "./site.scss";
}


:root {
  --pt-text-color-dark: #000000;
  --pt-text-color-light: #ffffff;
  --pt-primary-color-dark: #241629;
  --pt-primary-color-light: #fcddec;
  --pt-background-color: #1e90ff;
  --pt-top-menu-height: 100px;
}


