@use '../../site.scss' as site;

.overlay {
  position: absolute; 
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: site.$color-light;
  font-size: 4em;

  .progressBar {
    margin-top: 70%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 25%;
  }
}