$color-dark: #000000;
$color-light: #ffffff;
$color-selected: #ebb0ff;

$background-color-dark: #17081de5;
$background-backdrop-dark: #ffdee44c;
$background-blur-dark: blur(0.125em);

$background-color-medium: #301a37b2;
$background-backdrop-medium: #301a374c;
$background-backdrop-selected-medium: #301a3780;
$background-blur-medium: blur(0.31em);

$background-color-light: #fcddec;

$font-family-small: 'IntroSmall';
$font-family-small-italic: 'IntroSmallItalic';
$font-family-medium: 'IntroMedium';
$font-family-large: 'IntroLarge';

@font-face {
  font-family: 'IntroSmall';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Intro Regular Regular.otf') format('opentype');
}

@font-face {
  font-family: 'IntroSmallItalic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Intro Regular Italic Italic.otf') format('opentype');
}

@font-face {
  font-family: 'IntroMedium';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Intro Regular Regular.otf') format('opentype');
}

@font-face {
  font-family: 'IntroLarge';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Intro Bold Regular.otf') format('opentype');
}

@mixin desktop-display {
  @media only screen and (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi) {
    @content;
  }
}

@mixin mobile-display {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin font-small {
  font-family: $font-family-small;

  @include desktop-display {
    font-size: 1rem;
  }

  @include mobile-display {
    font-size: 2rem;
  }
}

@mixin font-small-italic {
  font-family: $font-family-small-italic;

  @include desktop-display {
    font-size: 1rem;
  }

  @include mobile-display {
    font-size: 2rem;
  }
}

@mixin font-medium {
  font-family: $font-family-medium;

  @include desktop-display {
    font-size: 1.5em;
  }

  @include mobile-display {
    font-size: 2.5rem;
  }
}

@mixin font-large {
  font-family: $font-family-large;

  @include desktop-display {
    font-size: 3rem;
  }

  @include mobile-display {
    font-size: 4rem;
  }
}

body {
  color: $color-light;
  background-color: $background-color-dark;
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}