@use '../../site.scss' as site;

.root {
    @include site.font-small;

    .previewContainer {
        width: 100%;
        margin-top: 1em;
        white-space: nowrap;
        overflow-x: scroll;
    }

    .fullPreviewContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 1em;
        padding-right: 1em;
        margin-top: 1em;
        max-height: 24em;
        overflow-y: scroll;
    
        // .item {
        //     display: inline-block;
        //     width: 6em;
        //     height: 6em;
        //     text-align: center;

        //     .iconContainer {
        //         width: 0px;
        //         height: 0px;

        //         .icon {
        //             position: relative;
        //             width: 1.0em;
        //             height: 1.0em;
        //             margin-top: 0.5em;
        //             margin-left: 3.5em;
        //         }
        //     }

        //     .circle {
        //         display: block;
        //         border-radius: 50%;
        //         background-color: #d9d9d9;
        //         width: 4em;
        //         height: 4em;
        //         margin-left: 1em;

        //         .icon {
        //             width: 1.5em;
        //             height: 1.5em;
        //             padding-top: 1.25em;
        //             color: #000000;
        //             fill: #000000;
        //         }
        //     }

        //     .label {
        //         color: site.$color-light;
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         padding-top: 0.5em;
        //         max-height: 1.25em;
        //     }
        // }
    }

    .item {
        display: inline-block;
        width: 6em;
        height: 6em;
        text-align: center;

        .iconContainer {
            width: 0px;
            height: 0px;

            .icon {
                position: relative;
                width: 1.0em;
                height: 1.0em;
                margin-top: 0.5em;
                margin-left: 3.5em;
            }
        }

        .circle {
            display: block;
            border-radius: 50%;
            background-color: #d9d9d9;
            width: 4em;
            height: 4em;
            margin-left: 1em;

            .icon {
                width: 1.5em;
                height: 1.5em;
                padding-top: 1.25em;
                color: #000000;
                fill: #000000;
            }
        }

        .label {
            color: site.$color-light;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-top: 0.5em;
            max-height: 1.25em;
        }
    }
}