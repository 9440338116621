@use '../../site.scss' as site;

.closedHeight {
  height: 17.5em;
}

.patternHeight {
  height: 31em;
}

.tileCropSizeHeight {
  height: 25.5em;
}

.adjustHeight {
  height: 28.5em;
}

.menu {
  @include site.font-small;

  position: absolute;
  width: 100%;
  bottom: 0px;

  border-top-left-radius: 1.25em;
  border-top-right-radius: 1.25em;

  fill: site.$color-light;
  color: site.$color-light;  
  background-color: site.$background-color-medium;
  backdrop-filter: site.$background-blur-medium;

  .topMenu {
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 3.5em;
      height: 3.5em;
      text-align: center;

      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin: auto;
        margin-top: 1em;
      }
    }

    .selected {
      fill: site.$color-selected;
      background-color: site.$background-backdrop-selected-medium;
    }
  }

  .selectedMenuContainer {
      width: 100%;
      height: 1.5em;
      margin-top: 1em;
      
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      flex-direction: row;
  
      .left {
        width: 25%;
        padding-left: 2em;
        text-align: left;
      }
  
      .middle {
        width: 40%;
        @include site.font-medium;
        text-align: center;
      }
  
      .right {
        width: 25%;
        padding-right: 2em;
        text-align: right;
      }
  
      .item {        
        height: 100%;
  
        .icon {
          width: 1.5em;
          height: 1.5em;
        }
      }
  }

  .patternGroupsContainer {
    width: 100%;
    height: 3.5em;
    margin-top: 1em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 3.5em;
      height: 3.5em;
      text-align: center;

      border-top-left-radius: 0.25em;
      border-top-right-radius: 0.25em;

      .triangleIcon {
        width: 1.8em;
        height: 1.8em;
        margin: auto;
        margin-top: 0.8em;
      }

      .itemIcon {
        width: 1.5em;
        height: 1.5em;
        margin: auto;
        margin-top: 1em;
      }
    }

    .selected {
      fill: site.$color-selected;
      background-color: site.$background-backdrop-selected-medium;
    }
  }

  .patternContainer {
      width: 100%;
      padding-top: 1.5em;
      overflow-x: scroll;
      white-space: nowrap;
      background-color: site.$background-backdrop-selected-medium;

      .item {
        display: inline-block;
        width: 6em;
        height: 5em;
        text-align: center;

        .patternIcon {
          width: 2em;
          height: 2em;
          margin-bottom: 0.2em;
        }
      }

      .selected {
        color: site.$color-selected;
        fill: site.$color-selected;
      }
  }

  .patternFooter {
    height: 4em;
    background-color: site.$background-backdrop-selected-medium;
  }

  .tileCropSizeContainer {
    width: 100%;
    height: 3em;
    margin-top: 1em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 6em;
      height: 3em;
      text-align: center;

      .title {
        padding-top: 0.5em;
        @include site.font-medium;
      }

      .correctionItem {
        width: 3em;
        height: 3em;
        background-color: site.$background-backdrop-selected-medium;
        border-radius: 50%;
        margin: auto;

        .icon {
          width: 1.5em;
          height: 1.5em;
          margin: auto;
          padding-top: 0.75em;
        }
      }
    }
  }

  .adjustSettings {
    text-align: center;
    // display: flex;
    // flex-wrap: nowrap;
    // justify-content: center;
    // align-items: flex-start;
    // align-content: flex-start;
    // flex-direction: row;

    .title {
      margin-top: 1em;
      @include site.font-medium;
    }

    .sliderContainer {
      margin-top: 1em;

      .slider {
        width: 50%;
      }
    }
  }
}