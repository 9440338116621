.root {
    height: 100vh;
    background-color: #ffffff;

    .container {
        width: 40em;
        height: 40em;
        padding-top: 80%;
        margin-left: auto;
        margin-right: auto;
        
        .animation {
            width: 100%;
        }
    }
}