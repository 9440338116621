@use '../../site.scss' as site;

.smallMenu {
  height: 5em;
}

.normalMenu {
  height: 7em;
}

.largeMenu {
  height: 13em;
}

.root {
  @include site.font-small;

  width: 100%;
  position: absolute;
  left: 0px;  
  bottom: 0px;

  border-top-left-radius: 1.25em;
  border-top-right-radius: 1.25em;  

  fill: site.$color-light;
  color: site.$color-light;  
  background-color: site.$background-color-dark;
  backdrop-filter: site.$background-blur-dark;

  .back {
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .save {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .title {
    position: absolute;
    top: 1em;
    margin-left: auto;
    margin-right: auto;
  }

  .selected {
    fill: site.$color-selected;
    color: site.$color-selected;
    --color-1: yellow;
  }

  .titleContainer {
    width: 100%;
    height: 1.5em;
    margin-top: 1.0em;
    
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .left {
      width: 20%;
      padding-left: 2em;
      text-align: left;
    }

    .middle {
      width: 50%;
      @include site.font-medium;
      text-align: center;
      
    }

    .right {
      width: 20%;
      padding-right: 2em;
      text-align: right;
    }

    .item {
      
      height: 100%;

      .icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .patternGroupsContainer {
    width: 100%;
    margin-top: 2em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 3.5em;
      height: 1.5em;
      text-align: center;

      .triangleIcon {
        width: 1.8em;
        height: 1.8em;
        margin-top: -0.2em;
      }

      .itemIcon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .patternContainer {
    width: 100%;
    margin-top: 2em;
    overflow-x: scroll;
    white-space: nowrap;


    .item {
      display: inline-block;
      width: 6em;
      height: 4em;
      text-align: center;

      .patternIcon {
        width: 2em;
        height: 2em;
        margin-bottom: 0.2em;
      }
    }
  }

  .adjustTitlesContainer {
    width: 100%;
    margin-top: 2em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      padding-left: 1em;
      padding-right: 1em;
      height: 1.5em;
      text-align: center;
    }
  }

  .adjustSliderContainer {
    padding-top: 2em;
    text-align: center;

    .slider {
      width: 50%;
    }
  }

  .menuItemContainer {
    height: 100%;

    overflow: auto;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 5em;
      height: 100%;
      text-align: center;

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin-bottom: 0.2em;
      }
    }
  }
}