@use '../../site.scss' as site;

.root {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);

    @include site.font-small;

    .dialog {
        width: 70%;
        height: 14em;
        margin-left: auto;
        margin-right: auto;
        margin-top: calc(50vh - 7em);
        border-radius: 1em;
        color: site.$color-dark;
        background-color: site.$background-color-light;
        text-align: center;

        .close {
            text-align: right;
            padding-top: 0.5em;
            padding-right: 0.5em;

            .icon {
                width: 1em;
                height: 1em;
                fill: site.$color-dark;
            }
        }

        .title {
            @include site.font-medium;
            text-align: center;
            padding-top: 0.5em;
        }

        .inputContainer {
            padding-top: 1em;

            .input {
                @include site.font-small;
                height: 3em;

                width: calc(90% - 2em);
                padding: 0%;
                border: none;
                border-radius: 0.3125em;
                
                background-color: site.$color-light;
            }

            .input:focus {
                @include site.font-small;
            }

            .input::placeholder {
                @include site.font-small-italic;
                text-align: center;
            }

            .inputPadding {
                padding-left: 1em;
                padding-right: 1em;
            }

            .inputButton {
                height: 3em;
                width: calc(100% - 2em);
                color: site.$color-light;
                background-color: site.$color-dark;
                @include site.font-small;
                border: none;
                border-radius: 0.3125em;
                padding: 0%;
            }

            .inputButtonNormal {
                height: 3em;
                width: calc(100% - 2em);
                color: site.$color-dark;
                background-color: site.$background-color-light;
                @include site.font-small;
                border: none;
                border-radius: 0.3125em;
                padding: 0%;
            }
        }
    }
}