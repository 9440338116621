@use 'site.scss' as site;

.root {
  color: site.$color-light;
  background-color: site.$background-color-dark;
  height: 2.5em;
  @include site.font-medium;
  font-size: 3rem !important;
  font-weight: 500;

  .bar {
    display: flex;
    justify-content: space-between;
    height: 2.5em;
  }

  .left {
    padding-left: 1em;
    padding-top: 0.75em;
  }

  .right {
    padding-right: 1em;
    padding-top: 0.75em;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background-color: rgba(0,0,0,.5);

    .openBar {
      color: site.$color-dark;
      background-color: site.$background-color-light;
    }

    .openMenu {
      display: flex;
      justify-content: space-between;
      height: 7em;
      color: site.$color-dark;
      background-color: site.$background-color-light;

      .openMenuLeft {
        width: 45%;
        padding-top: 1em;
        text-align: center;

        .profileImage {
          border-radius: 50%;
          width: 5em;
        }
      }

      .openMenuRight {
        width: 45%;
        padding-top: 1.5em;

        .openMenuRightItem {
          padding-bottom: 1em;
        }
      }
    }
  }
}