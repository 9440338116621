@use '../../site.scss' as site;

.controls {  
  @include site.font-small;

  position: absolute;
  width: 100%;
  height: 14em;
  bottom: 0px;

  border-top-width: 1px;
  border-top-color: white;
  border-top-style: solid;
  border-top-left-radius: 1.25em;
  border-top-right-radius: 1.25em;

  fill: site.$color-light;
  color: site.$color-light;  
  background-color: site.$background-color-dark;
  backdrop-filter: site.$background-blur-dark;

  .topContainer {
    width: 100%;
    margin-top: 1em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    .item {
      width: 6em;
      height: 6em;
      text-align: center;
    }

    .logo {
      width: 2.5em;
      height: 2.5em;
      margin: auto;
      padding-top: 1em;
    }

    .takeImageItem {
      width: 5em;
      height: 5em;
      margin-top: 0.5em;
      margin-left: 0.5em;
      background-image: url('../../icons/camera_button_state_default.svg');
      background-repeat: no-repeat;
      background-size: cover;
      user-select: none;
    }

    .takeImageItemPressed {
      opacity: 0;
      width: 5em;
      height: 5em;
    }

    .takeImageItemPressed:active {
      animation-name: takeImagePressedAnimation;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }

    .videoRecodingProgress {
      transform: rotate(-90deg);

      .background {
        fill: none; 
	      stroke: #ffffff;
	      stroke-width: 6px;
      }

      .progress {
        fill: none; 
	      stroke: #e083ff;
	      stroke-width: 6px;
	      stroke-dasharray: 100;
        transition: stroke-dasharray 0.7s linear 0s;
      }

      .inner {
        fill: #e083ff; 
	      stroke: none;
      }
    }

    @keyframes takeImagePressedAnimation {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .cameraDirectionItem {
      width: 3em;
      height: 3em;
      background-color: site.$background-backdrop-dark;
      border-radius: 50%;
      margin: auto;
      margin-top: 1.5em;

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin: auto;
        padding-top: 0.75em;
      }      
    }
  }

  .previewContainer {
    width: 100%;
    margin-top: 1em;
    white-space: nowrap;

    @include site.desktop-display {
      overflow-x: hidden;
    } 

    @include site.mobile-display {
      overflow-x: scroll;
    }

    .item {
      display: inline-block;
      width: 4.5em;
      height: 4.5em;
      margin-left: 0.5em;
      padding-right: 0.3em;
      text-align: center;
    }

    .itemIcon {
      margin-bottom: 0.5em;
      margin-left: -2.25em;
      width: 1.5em;
      height: 1.5em;
    }
  }
}