@use '../../site.scss' as site;

.root {
    height: 100vh;
    color: site.$color-dark;
    background: linear-gradient(180deg, #FFDEE4 0%, #F2D9FF 100%);

    .title {
        @include site.font-large;
        text-align: center;
        padding-top: 70%;
    }

    .controlContainer {
        text-align: center;
        padding-top: 2em;

        .button {
            height: 3em;
            width: 70%;
            @include site.font-small;
            border: none;
            border-radius: 0.3125em;
            padding: 0%;
            background-color: site.$color-light;

            .icon {
                width: 1.5em;
                height: 1.5em;
                vertical-align: middle;
            }

            .text {
                display: inline-block;
                height: 1.5em;
                padding-top: 0.5em;
                padding-left: 0.5em;
                vertical-align: middle;
                color: site.$color-dark;
            }
        }

        .input {
            @include site.font-small;
            height: 3em;

            width: calc(70% - 2em);
            border: none;
            border-radius: 0.3125em;
            padding: 0%;
            background-color: site.$color-light;
        }

        .input:focus {
            @include site.font-small;
        }

        .input::placeholder {
            @include site.font-small-italic;
            text-align: center;
        }

        .inputPadding {
            padding-left: 1em;
            padding-right: 1em;
        }
        
        .inputButton {
            height: 3em;
            width: 70%;
            color: site.$color-light;
            background-color: site.$color-dark;
            @include site.font-small;
            border: none;
            border-radius: 0.3125em;
            padding: 0%;
        }
    }

    .error {
        @include site.font-small-italic;
        text-align: center;
        height: 1.5em;
        padding-top: 1.5em;
        color: red;
    }

    .footer {
        @include site.font-small-italic;
        text-align: center;
        height: 1.5em;
        padding-top: 1.5em;
    }

    .footerUnderlined {
        @include site.font-small-italic;
        text-align: center;
        text-decoration: underline;
        height: 1.5em;
        padding-top: 1.5em;
    }

    .signUp {
        @include site.font-small;
        text-align: center;
        text-decoration: underline;
        height: 1.5em;
        padding-top: 1.5em;
    }

    .singleLogo {
        position: absolute;
        width: 30em;
        height: 30em;
        left: -5em;

        animation-name: singleLogo;
        animation-duration: 4s;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    @keyframes singleLogo {
        0% {
            top: 0;
        }
        25% {
            left: -4em;
        }
        50% {
            top: 1.5em;
        }
        100% {
            top: 0;
            filter: blur(0.5rem);
        }
    }

    .fullLogoContainer {
        overflow: hidden;
        width: 100%;
        height: 100%;
   

        .fullLogo {
            margin-top: -20em;
            margin-left: -15em;
            width: 130em;
            height: 130em;

            animation-name: fullLogo;
            animation-duration: 2s;
            animation-direction: alternate;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        @keyframes fullLogo {
            0% {
                margin-left: -15em;
            }
            50% {
                margin-left: -15.5em;
            }
            75% {
                margin-top: -20.5em;
            }
            100% {
                margin-left: -15em;
                filter: blur(1rem);
            }
        }
    }
}

