@use '../../site.scss' as site;

.page {
  @include site.font-small;
  fill: site.$color-light;
  color: site.$color-light;

  user-select: none;

  .header {
    display: flex;
    justify-content: space-between;

    .left {
      padding-left: 40px;
      padding-top: 20px;
    }

    .middle {
      @include site.font-medium;
      padding-top: 20px;
    }
  
    .right {
      padding-right: 40px;
      padding-top: 20px;
    }

    .icon {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 1em;

    .imageItem {
      width: 5.5em;
      height: 5.5em;
      padding-left: 0.5em;
      padding-bottom: 0.5em;

      .image {
        width: 5.5em;
        height: 5.5em;

        -webkit-touch-callout: none;
        border: 2px solid transparent;
      }

      .selectedImage {
        border: 2px solid site.$color-light;
      }

      .iconContainer {
        width: 0px;
        height: 0px;
        fill: site.$color-light;
        color: site.$color-light;

        .imageIcon {
          position: relative;
          width: 2em;
          height: 2em;
          margin-top: 3em;
          margin-left: 3em;
        }

        .selectedIcon {
          position: relative;
          margin-top: 0.5em;
          margin-left: 4em;
          width: 1em;
          height: 1em;
        }
      }
    }
  }
}